









































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { ProductSkuDto } from "@/api/appService";

@Component({
  name: "salesManagementDetail",
})
export default class SalesManagementDetail extends Vue {
  detailId?: number;
  detail: ProductSkuDto = {};
  loading = true;
  loadingEnterprise = true;

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.productSku.get({ id: this.detailId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
    });
    console.log(this.detail);
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
